import { SelectInput, Title } from "components/common";

const QuestionFormOptions = ({
  scripts,
  explanations,
  topics,
  formData,
  setFormData,
  editForm,
  disabled,
}) => {
  const renderActions = () => {
    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "baseline",
        }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <SelectInput
            options={scripts}
            style={{ width: 180 }}
            placeholder="Select Scripts"
            getOptionValue={(e) => e?.editor_template_id}
            getOptionLabel={(e) => e?.page_name}
            value={formData?.scripts}
            mode="multiple"
            onChange={(value, options) => {
              setFormData((prev) => ({
                ...prev,
                scripts: options,
                explanation: [],
              }));
            }}
            getPopupContainer={false}
            disabled={disabled}
          />
          <SelectInput
            options={explanations}
            style={{ width: 180 }}
            placeholder="Select Explanation"
            getOptionValue={(e) => e?.title}
            getOptionLabel={(e) => e?.title}
            value={formData?.explanation}
            mode="multiple"
            onChange={(value, options) => {
              setFormData((prev) => ({ ...prev, explanation: options }));
            }}
            getPopupContainer={false}
            disabled={disabled}
          />
          <SelectInput
            placeholder="Add Topics"
            options={topics || []}
            style={{ width: 180 }}
            getOptionValue={(e) => e?._id}
            getOptionLabel={(e) => e?.PageTopics}
            onChange={(val, options) => {
              setFormData((prev) => ({ ...prev, topics: options }));
            }}
            value={formData?.topics}
            mode="multiple"
            disabled={disabled}
          />
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        marginBottom: "20px",
      }}
    >
      <Title level={3}>
        {editForm ? "Update Question" : "Create Question:"}
      </Title>
      {renderActions()}
    </div>
  );
};

export default QuestionFormOptions;
