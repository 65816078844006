import { Card, notification, Spinner, Title } from "components/common";
import QuestionForm from "components/custom/question/questionForm";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetQuestionDetailQuery } from "../apis";
import {
  useAddQuestionMutation,
  useUpdateQuestionsMutation,
} from "features/script/api";
import { SCRIPT_QUESTIONS_LISTING } from "constants/app-paths";

const AddEditQuestionPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    data: question,
    isLoading,
    error,
    refetch,
  } = useGetQuestionDetailQuery(
    { questionId: params?.id },
    { skip: !params?.id, refetchOnMountOrArgChange: true }
  );

  const [handleUpdate, { isLoading: updateLoading }] =
    useUpdateQuestionsMutation();
  const [addQuestion, { isLoading: submitLoading }] = useAddQuestionMutation();

  const validateForm = (data) => {
    if (!data?.scripts?.length) {
      notification.error({ message: "Please select a script first!" });
      return false;
    } else if (!data?.explanation?.length) {
      notification.error({ message: "Please select an explanation first!" });
      return false;
    } else if (!data?.description?.length) {
      notification.error({
        message: "Please add description for the question!",
      });
      return false;
    } else if (!data?.correctAnswer?.length) {
      notification.error({ message: "Please select correct answer!" });
      return false;
    }
    return true;
  };

  const updatedQuestion = async (data) => {
    let validate = validateForm(data);
    const body = { questionList: [data], deletedQuestionIds: [] };
    if (validate) {
      const res = await handleUpdate(body);
      if (res) {
        if (res?.error) {
        } else {
          notification.success({ message: "Questions Updated Successfully!" });
          navigate(SCRIPT_QUESTIONS_LISTING);
        }
      }
    }
  };

  const createQuestion = async (data) => {
    let validate = validateForm(data);
    if (validate) {
      const res = await addQuestion({
        question: [
          {
            ...data,
          },
        ],
      });
      if (res?.error) {
        notification.error({ message: "Network Error" });
      } else {
        notification.success({ message: "Question Added Successfully!" });
        navigate(SCRIPT_QUESTIONS_LISTING);
      }
    }
  };

  return params?.id && isLoading ? (
    <Spinner />
  ) : error ? (
    "Error"
  ) : (
    <Card>
      <QuestionForm
        showSubmit={true}
        onSubmit={(data) => {
          if (params?.id) {
            updatedQuestion(data);
          } else {
            createQuestion(data);
          }
        }}
        data={question?.[0]}
        editForm={!!params?.id}
        submitLoading={submitLoading || updateLoading}
      />
    </Card>
  );
};

export default AddEditQuestionPage;
