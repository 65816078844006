import { Button, Icon, Modal, Title, notification } from "components/common";
import { EDIT_ASSESSMENT } from "components/svg";
import {
  useGetAllQuestionsOfScriptQuery,
  useLazyGetAllQuestionsOfScriptQuery,
  useUpdateQuestionsMutation,
} from "features/script/api";
import { useDispatch } from "react-redux";
import { setQuestionsData } from "store/scriptSlice";
import EditQuestionModalBody from "./editQuestionModalBody";
import { useEffect, useState } from "react";
import ViewQuestion from "components/custom/questions/viewQuestion";

const ViewEditQuestionModal = ({
  viewEditModal,
  setViewEditModal,
  questions,
  explanations,
  allScripts,
  apiData,
  scriptData,
  handleAddQuestion,
  detailPage,
  allTOpics,
}) => {
  const dispatch = useDispatch();

  const [getAllScriptsQuestions, {}] = useLazyGetAllQuestionsOfScriptQuery();

  const { data: questionsData } = useGetAllQuestionsOfScriptQuery(
    { scriptId: scriptData?.scriptId },
    { refetchOnMountOrArgChange: true, skip: !scriptData?.scriptId }
  );

  const [scriptQuestions, setScriptQuestions] = useState(
    questionsData || questions
  );

  useEffect(() => {
    setScriptQuestions(questionsData || questions);
  }, [questionsData, questions]);

  const [handleUpdate, _] = useUpdateQuestionsMutation();

  const handleOk = async () => {
    if (scriptData?.scriptId) {
      let deletedQuestionIds = [];
      apiData.forEach((it) => {
        const find = scriptQuestions?.find(
          (e) => e?.questionId == it.questionId
        );
        if (!find) {
          deletedQuestionIds.push(it?.questionId);
        }
      });
      const body = { questionList: scriptQuestions, deletedQuestionIds };
      const res = await handleUpdate(body);
      if (res) {
        if (res?.error) {
        } else {
          getAllScriptsQuestions({ scriptId: scriptData?.scriptId });
          setViewEditModal(false);
          notification.success({ message: "Questions Updated Successfully!" });
        }
      }
    } else {
      dispatch(setQuestionsData(scriptQuestions));
      setViewEditModal(false);
    }
  };

  const handleSingleQuestionUpdate = async (updatedQuestion, index) => {
    let tempQuestions = JSON.parse(JSON.stringify(apiData));
    if (tempQuestions?.length) {
      tempQuestions[index] = updatedQuestion;
    } else {
      tempQuestions = [updatedQuestion];
    }
    const body = { questionList: tempQuestions, deletedQuestionIds: [] };
    const res = await handleUpdate(body);
    if (res) {
      if (res?.error) {
      } else {
        notification.success({ message: "Question Updated Successfully!" });
      }
    }
  };

  const handleUpdateQuestions = (updatedQuestion, index) => {
    let tempQuestions = JSON.parse(JSON.stringify(scriptQuestions));
    if (tempQuestions?.length) {
      tempQuestions[index] = updatedQuestion;
    }
    setScriptQuestions(tempQuestions);
  };

  const handleRemoveQuestion = (index) => {
    let tempQuestions = JSON.parse(JSON.stringify(scriptQuestions));
    tempQuestions = tempQuestions.filter((e, i) => i != index);
    setScriptQuestions(tempQuestions);
  };

  const resetQuestions = () => {
    if (scriptData?.scriptId) {
      getAllScriptsQuestions({ scriptId: scriptData?.scriptId });
    } else {
      dispatch(setQuestionsData(questions));
    }
  };

  const renderHeader = () => {
    return viewEditModal == "view" ? (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title level={3}>{`View Questions`}</Title>
        <div style={{ display: "flex", gap: "25px", alignItems: "center" }}>
          {!detailPage && (
            <>
              <Button
                type="primary"
                icon="HiPlus"
                text="Add Question"
                onClick={handleAddQuestion}
              />
              <Button
                onClick={() => setViewEditModal("edit")}
                text="Edit"
                icon={<EDIT_ASSESSMENT />}
              />
            </>
          )}
          <Icon
            className="pointer"
            icon="ImCross"
            size={12}
            onClick={() => {
              resetQuestions();
              setViewEditModal(null);
            }}
          />
        </div>
      </div>
    ) : (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title level={3}>{`Edit Questions`}</Title>
        <Icon
          className="pointer"
          icon="ImCross"
          size={12}
          onClick={() => {
            resetQuestions();
            setViewEditModal(null);
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      visible={viewEditModal}
      onCancel={() => {
        resetQuestions();
        setViewEditModal(null);
      }}
      onOk={handleOk}
      title={renderHeader()}
      description={`Total Questions:  ${scriptQuestions?.length}`}
      width="1800px"
      destroyOnClose
      okText="Update All Questions"
      closable={false}
      getContainer={"app-root"}
      footer={viewEditModal == "view"}
    >
      {viewEditModal == "view" ? (
        <ViewQuestion scriptQuestions={scriptQuestions} />
      ) : (
        <EditQuestionModalBody
          scriptQuestions={scriptQuestions}
          handleUpdateQuestions={handleUpdateQuestions}
          explanations={explanations}
          allScripts={allScripts}
          handleRemoveQuestion={handleRemoveQuestion}
          handleSingleQuestionUpdate={handleSingleQuestionUpdate}
          showSingleUpdate={!!scriptData?.scriptId}
          allTOpics={allTOpics}
        />
      )}
    </Modal>
  );
};

export default ViewEditQuestionModal;
