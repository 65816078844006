import { Card, Group, Spinner, Title } from "components/common";
import ViewQuestion from "components/custom/questions/viewQuestion";
import React, { useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetQuestionDetailQuery } from "../apis";
import {
  useGetPageTopicsQuery,
  useGetSearchListingScriptTopicsQuery,
} from "features/script/api";
import { TagsWithPlus } from "components/custom";

const ViewScriptQuestion = () => {
  const params = useParams();

  const {
    data: question,
    isLoading,
    error,
    refetch,
  } = useGetQuestionDetailQuery(
    { questionId: params?.id },
    { skip: !params?.id, refetchOnMountOrArgChange: true }
  );

  const { data: allScripts } = useGetPageTopicsQuery("");
  const { data: allTopics } = useGetSearchListingScriptTopicsQuery();

  const findScript = (scripts) => {
    if (allScripts?.length && scripts?.length) {
      return allScripts
        .filter(
          (e) =>
            scripts.includes(e.editor_template_id) || scripts?.includes(e?._id)
        )
        ?.map((e) => e?.page_name);
    }
    return ["-"];
  };

  const findTopic = (topics) => {
    if (allTopics?.data?.length && topics?.length) {
      return allTopics?.data
        .filter((topic) => topics.includes(topic._id))
        ?.map((e) => e?.PageTopics);
    }
    return ["-"];
  };

  const renderQuestionOptions = useCallback(() => {
    return (
      <div style={{ marginLeft: "1%" }}>
        <div style={{ display: "flex", gap: "10px" }}>
          <Title level={3}>Scripts:</Title>
          <TagsWithPlus
            data={findScript(question?.[0]?.scripts)}
            plusAfter={5}
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <Title level={3}>Explanations:</Title>
          <TagsWithPlus
            data={question?.[0]?.explanation?.map((e) => e?.title)}
            plusAfter={5}
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <Title level={3}>Topics:</Title>
          <TagsWithPlus data={findTopic(question?.[0]?.topics)} plusAfter={5} />
        </div>
      </div>
    );
  }, [allScripts, allTopics, question]);

  return isLoading ? (
    <Spinner />
  ) : error ? (
    "Error"
  ) : (
    <Card style={{ minHeight: "88vh", overflow: "auto" }}>
      <ViewQuestion scriptQuestions={[question?.[0]]} showBorder={false} />
      {renderQuestionOptions()}
    </Card>
  );
};

export default ViewScriptQuestion;
