import { Button, Checkbox, TextInput, Title, Tooltip } from "components/common";
import { TABLE_DELETE_ICON } from "components/svg";
import { useEffect, useState } from "react";
import theme from "utils/themeVars";
import { generateUUID } from "pubnub";

const QuestionAnswerOptions = ({
  options,
  setOptions,
  setCorrectAnswer,
  correctAnswer,
}) => {
  const [formOptions, setFormOptions] = useState([
    { answerId: generateUUID(), answer: "" },
    { answerId: generateUUID(), answer: "" },
    { answerId: generateUUID(), answer: "" },
    { answerId: generateUUID(), answer: "" },
  ]);
  const [formAnswer, setFormAnswer] = useState([]);

  useEffect(() => {
    if (options?.length) {
      setFormOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (correctAnswer?.length) {
      setFormAnswer(correctAnswer);
    }
  }, [correctAnswer]);

  const handleChangeAnswer = (value, item) => {
    let tempOptions = JSON.parse(JSON.stringify(formOptions));
    tempOptions = tempOptions.map((e, i) => {
      if (e?.answerId == item?.answerId) {
        return { ...e, answer: value };
      } else {
        return e;
      }
    });
    setOptions(tempOptions);
  };

  const handelAddAnswer = () => {
    setOptions([...formOptions, { answerId: generateUUID(), answer: "" }]);
  };

  const handleRemoveAnswer = (item) => {
    let tempOptions = JSON.parse(JSON.stringify(formOptions));
    tempOptions = tempOptions.filter((e, i) => e?.answerId != item?.answerId);

    let tempCorrectAnswer = JSON.parse(JSON.stringify(formAnswer));
    tempCorrectAnswer = tempCorrectAnswer?.find((e) => e == item?.answerId)
      ? []
      : tempCorrectAnswer;

    setCorrectAnswer(tempCorrectAnswer);
    setOptions(tempOptions);
  };

  const answerItem = (item, index) => {
    const alphabetIndex = String.fromCharCode(65 + index);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "10px auto",
          gap: "10px",
          alignItems: "center",
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <Tooltip title="Mark as right option">
          <Checkbox
            key={index}
            onChange={() => setCorrectAnswer([item?.answerId])}
            checked={
              formAnswer?.length
                ? formAnswer?.find((e) => e == item?.answerId)
                : false
            }
            style={{ backgroundColor: "white" }}
          ></Checkbox>
        </Tooltip>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: theme["blue50"],
            color: theme["blue200"],
          }}
        >
          {alphabetIndex}
        </div>
        <div style={{ width: "100%", paddingLeft: "10px" }}>
          <TextInput
            value={item?.answer}
            placeholder={"Add Option"}
            onChange={(e) => {
              handleChangeAnswer(e?.target?.value, item);
            }}
          />
        </div>

        {!(index == formOptions?.length - 1 && formOptions?.length < 5) &&
          formOptions?.length > 2 && (
            <Button
              icon={<TABLE_DELETE_ICON />}
              iconProps={{
                size: "1.8rem",
                color: "#fc5327",
                backgroundColor: "white",
              }}
              style={{
                backgroundColor: "white",
              }}
              outlined
              onClick={() => {
                handleRemoveAnswer(item);
              }}
            />
          )}

        {index == formOptions?.length - 1 && formOptions?.length < 5 && (
          <Button
            icon="HiPlus"
            style={{
              background: "transparent",
              border: `1px solid ${theme["blue100"]}`,
              color: theme["primary-color"],
            }}
            iconProps={{ color: theme["primary-color"] }}
            onClick={handelAddAnswer}
          />
        )}
      </div>
    );
  };

  return formOptions?.length ? (
    <div style={{ marginTop: "2%" }}>
      <Title level={3}>Create Answers:</Title>
      {formOptions?.map((item, index) => answerItem(item, index))}
    </div>
  ) : null;
};
export default QuestionAnswerOptions;
