import { convertQueryObjToString } from "utils";
const { default: parentApi } = require("store/parentApi");

const questionApis = parentApi.injectEndpoints({
  endpoints: (build) => ({
    getAllQuestions: build.query({
      query: (body) => {
        return {
          url: `/question/getAllQuestions`,
          method: "POST",
          body: body,
        };
      },
      providesTags: ["allScriptQuestion"],
    }),
    getQuestionDetail: build.query({
      query: (body) => {
        return {
          url: `/question/getQuestion`,
          method: "POST",
          body: body,
        };
      },
      providesTags: ["questionDetail"],
    }),
  }),
});

export const { useGetAllQuestionsQuery, useGetQuestionDetailQuery } =
  questionApis;
