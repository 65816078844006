import { Group, Modal, notification, SelectInput } from "components/common";
import { SCRIPT_QUIZ_PATH } from "constants/app-paths";
import {
  useGetPageTopicsQuery,
  useLazyGetScriptsExplanationsQuery,
  useStartQuizMutation,
} from "features/script/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateQuiz = ({ visible, setVisible }) => {
  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();
  const { data: scripts, isLoading } = useGetPageTopicsQuery("");
  const [getScriptsExplanations, { data: explanations }] =
    useLazyGetScriptsExplanationsQuery();

  const getExplanations = async (scripts) => {
    await getScriptsExplanations({ scriptIds: scripts });
  };
  const [handleStartQuiz, _] = useStartQuizMutation();

  useEffect(() => {
    if (formData?.scripts) {
      getExplanations(formData?.scripts);
    }
  }, [formData?.scripts]);

  const onStartQuiz = async () => {
    const res = await handleStartQuiz({
      scriptId: formData?.scripts,
      explanation: formData?.explanation,
    });
    if (res) {
      if (res?.error) {
      } else if (!!res?.data && res?.data?.length) {
        navigate(SCRIPT_QUIZ_PATH.replace(":id", res?.data));
      } else {
        notification.error({
          message: "No Questions Exists for the Selected Explanations",
        });
        setVisible(false);
        setFormData(null);
      }
    }
  };

  return (
    <Modal
      visible={visible}
      onOk={onStartQuiz}
      onCancel={() => {
        setFormData(null);
        setVisible(false);
      }}
      title={"Select Options for the Quiz"}
      okText={"Start Quiz"}
    >
      <Group gap="16px">
        <SelectInput
          options={scripts}
          style={{ width: 600 }}
          placeholder="Select Scripts"
          getOptionValue={(e) => e?.editor_template_id}
          getOptionLabel={(e) => e?.page_name}
          value={formData?.scripts}
          onChange={(value, options) => {
            setFormData((prev) => ({
              ...prev,
              scripts: value,
              explanation: [],
            }));
          }}
          getPopupContainer={false}
        />
        <SelectInput
          options={explanations}
          style={{ width: 600 }}
          placeholder="Select Explanation"
          getOptionValue={(e) => e?.title}
          getOptionLabel={(e) => e?.title}
          value={formData?.explanation}
          mode="multiple"
          onChange={(value, options) => {
            setFormData((prev) => ({ ...prev, explanation: options }));
          }}
          getPopupContainer={false}
        />
      </Group>
    </Modal>
  );
};
export default CreateQuiz;
