import { List } from "antd";
import { renderQuillHtml } from "configs/utils";
import theme from "utils/themeVars";
import QuestionItem from "./questionItem";
import { Title } from "components/common";

const ViewQuestion = ({ scriptQuestions, showBorder = true }) => {
  const renderItem = (item, index) => {
    return (
      <List.Item style={{ position: "relative", marginBottom: "1%" }}>
        <div
          style={{
            content: '""',
            position: "absolute",
            left: "0",
            top: "4%",
            bottom: "0",
            width: "50%",
            ...(showBorder && { borderLeft: `2px solid ${theme["blue200"]}` }),
          }}
        />
        <List.Item.Meta
          title={renderQuillHtml(
            `<p>
              <span style="font-weight: bold; font-size: 18px;">Question:</span>
              <span style="display: inline;">${item?.description}</span>
            </p>`,
            undefined,
            {
              margin: "-10px 0px -10px -10px",
            }
          )}
          description={<QuestionItem question={item} />}
        />
      </List.Item>
    );
  };
  return <List dataSource={scriptQuestions} renderItem={renderItem} />;
};

export default ViewQuestion;
