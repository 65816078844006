export const NAME_SPACE = "en";

export const LOGIN_PATH = "/login";
export const USER_VERIFIED_PATH = "/auth/email/verified";
export const DEFAULT_PATH = "/dashboard";
export const SCRIPT_PATH = "/scripts";
export const LIBRARY_DETAIL_PATH = "/library/:id";
export const ADD_SCRIPT_PATH = "/scripts/add";
export const EDIT_SCRIPT_PATH = "/scripts/:name/edit";
export const EDIT_LIBRARY_PATH = "/edit-library";
export const PRESENTATION_PATH = "/presentation";
export const PRESENTATION_DETAIL_PATH = "/presentation/:id";
export const PRESENTATION_ADD_PATH = "/presentation/add";
export const PRESENTATION_EDIT_PATH = "/presentation/edit/:id";
export const DASHBOARD = "/dashboard";
export const CREATE_STUDY_SESSION = "/createstudysession";
export const STUDY_SESSION = "/studysessions";
export const ACCOUNTS_PATH = "/accounts";
export const SCRIPT = "/script";
export const EDITED_PAGES = "/editedpages";
export const FAVORITE_PAGES = "/favorite-scripts";
export const HIDDEN_PAGES = "/hiddenpages";
export const COMPARE = "/compare";
export const CREATE_GRAPH = "/creategraph";
export const APPROVED_PAGES = "/approvedpages";
export const CONTACT_US = "/contactus";
export const SAVED = "/saved";
export const SCRIPT_DETAIL_PATH = "/scripts/:id";
export const TEMPLATE_DETAIL_PATH = "/scripts/:name";
export const PRESENTATION_DETAIL_VIEW_PUBLIC_PATH = "/presentation/details";
export const SCRIPT_TEMPLATE_SETTINGS_PATH = "/template-settings";
export const SCRIPT_FEEDBACK_PATH = "/script-feedback";
export const APP_USERS = "/app-users";
export const SCRIPT_TEMPLATE_SETTINGS_FORM_PATH = "/template-settings/add";
export const SCRIPT_QUIZ_LISTING = "/user/quiz";
export const SCRIPT_QUIZ_PATH = "/user/quiz/start/:id";
export const SCRIPT_QUIZ_RESULT_PATH = "/user/quiz/result:id";
export const SCRIPT_QUESTIONS_LISTING = "/script-questions";
export const SCRIPT_ADD_QUESTIONS = "/script-questions/add";
export const SCRIPT_EDIT_QUESTIONS = "/script-questions/:id";
export const SCRIPT_VIEW_QUESTIONS = "/script-questions/detail/:id";
