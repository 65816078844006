import { Icon } from "components/common";
import { renderQuillHtml } from "configs/utils";
import theme from "utils/themeVars";

const QuestionOptions = ({
  options,
  answer,
  isAnswered,
  optionSelected,
  onOptionSelection,
  resultPage,
}) => {
  const getOptionStyles = (item) => {
    if (!isAnswered) {
      return {
        border:
          optionSelected === item
            ? `2px solid ${theme["primary-color"]}`
            : `2px solid ${theme["blue50"]}`,
        backgroundColor: optionSelected === item ? theme["blue50"] : "white",
      };
    }

    const isCorrectAnswer = answer?.includes(item);

    if (optionSelected === item) {
      return {
        border: isCorrectAnswer
          ? "2px solid #4ca64c"
          : `2px solid ${theme["error-color"]}`,
        backgroundColor: isCorrectAnswer
          ? "#e5f2e5"
          : theme["error-color-light"],
      };
    }

    if (isCorrectAnswer) {
      return {
        border: "2px solid #4ca64c",
        backgroundColor: "#e5f2e5",
      };
    }

    return {
      border: `2px solid ${theme["blue50"]}`,
    };
  };
  const renderOption = (item, index) => {
    const isCorrectAnswer = answer?.includes(item?.answer);
    const alphabetIndex = String.fromCharCode(65 + index);
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "10px auto",
          gap: "10px",
          alignItems: "center",
          backgroundColor: "white",
          width: "100%",
          pointerEvents: isAnswered ? "none" : "auto",
        }}
        className={!resultPage && !isAnswered ? "pointer" : ""}
        onClick={() => {
          onOptionSelection(item?.answerId);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: theme["blue50"],
            color: theme["blue200"],
          }}
        >
          {alphabetIndex}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            width: "100%",
            borderRadius: "10px",
            paddingRight: "5px",
            ...getOptionStyles(item?.answerId),
          }}
        >
          {renderQuillHtml(item?.answer)}
          {isAnswered && isCorrectAnswer && (
            <Icon icon="MdOutlineCheckCircle" size={20} color="#4ca64c" />
          )}
          {!!isAnswered &&
            !answer?.includes(optionSelected) &&
            optionSelected == item?.answerId && (
              <Icon
                icon="RiCloseCircleLine"
                size={20}
                color={theme["error-color"]}
              />
            )}
        </div>
      </div>
    );
  };
  return options?.length
    ? options.map((e, index) => renderOption(e, index))
    : null;
};

export default QuestionOptions;
