import { Tag, Tooltip } from "components/common";
import React from "react";

const TagsWithPlus = ({ data, plusAfter }) => {
  return data?.length ? (
    data?.length > plusAfter ? (
      <>
        {data.map((item, index) => {
          return index < plusAfter ? (
            <Tag key={index} style={{ marginBottom: "0.5vh" }}>
              {item}
            </Tag>
          ) : null;
        })}
        <Tooltip
          placement="top"
          title={data.map((item, index) => {
            return index > plusAfter - 1 ? <div>{item}</div> : null;
          })}
        >
          <Tag key={"more"} style={{ marginBottom: "0.5vh" }}>
            {`+${data?.length - plusAfter}`}
          </Tag>
        </Tooltip>
      </>
    ) : (
      <>
        {data.map((item, index) => (
          <Tag key={index} style={{ marginBottom: "0.5vh" }}>
            {item}
          </Tag>
        ))}
      </>
    )
  ) : (
    <></>
  );
};

export default TagsWithPlus;
