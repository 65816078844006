import { Button, Title } from "components/common";
import TextEditor from "components/common/textEditor/textEditor";
import {
  useGetPageTopicsQuery,
  useGetScriptsExplanationsQuery,
  useGetSearchListingScriptTopicsQuery,
  useLazyGetScriptsExplanationsQuery,
} from "features/script/api";
import { useEffect, useState } from "react";
import theme from "utils/themeVars";
import QuestionAnswerOptions from "./questionAnswerOption";
import QuestionFormOptions from "./questionFormOptions";

const QuestionForm = ({
  data,
  onSubmit,
  showSubmit,
  editForm,
  submitLoading,
}) => {
  const [formData, setFormData] = useState({});
  const { data: allScripts, isLoading } = useGetPageTopicsQuery("");
  const { data: allTopics } = useGetSearchListingScriptTopicsQuery();

  const [getScriptsExplanations, { data: allExplanations }] =
    useLazyGetScriptsExplanationsQuery();

  const getExplanations = async (scripts) => {
    await getScriptsExplanations({ scriptIds: scripts });
  };

  useEffect(() => {
    if (formData?.scripts?.length) {
      getExplanations(formData?.scripts);
    }
  }, [formData?.scripts]);

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  return (
    <div
      style={{
        border: `1px solid ${theme["blue100"]}`,
        borderRadius: "10px",
        margin: "2%",
        padding: "2%",
      }}
    >
      <QuestionFormOptions
        scripts={allScripts}
        explanations={allExplanations}
        topics={allTopics?.data}
        formData={formData}
        setFormData={setFormData}
        editForm={editForm}
      />
      <TextEditor
        key={"Question"}
        placeholder={"Add question here"}
        value={formData?.description}
        onChange={(value) => {
          setFormData((prev) => ({ ...prev, description: value }));
        }}
        editorHeight={200}
      />
      {formData?.questionId && (
        <Title style={{ marginTop: "1%" }} level={5}>
          Question ID: {data?.questionId}
        </Title>
      )}

      <QuestionAnswerOptions
        options={formData?.options}
        setOptions={(options) => {
          setFormData((prev) => ({ ...prev, options }));
        }}
        correctAnswer={formData?.correctAnswer}
        setCorrectAnswer={(correctAnswer) => {
          console.log("===corr", correctAnswer);
          setFormData((prev) => ({ ...prev, correctAnswer }));
        }}
      />

      <div style={{ marginTop: "2%" }}>
        <Title level={3}>Add Additional Info:</Title>
        <TextEditor
          key={"additonalInfo"}
          placeholder={"Add additional info here"}
          value={formData?.additionalInfo}
          onChange={(value) => {
            setFormData((prev) => ({ ...prev, additionalInfo: value }));
          }}
          editorHeight={200}
        />
      </div>

      {showSubmit && (
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "2%" }}
        >
          <Button
            text={editForm ? "Update Question" : "Submit Question"}
            type="primary"
            onClick={() => onSubmit(formData)}
            loading={submitLoading}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionForm;
